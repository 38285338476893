import React from 'react'
import Img from "gatsby-image/withIEPolyfill"
import {Button} from 'components/buttons'

import CardStyles from './card.module.css'

const twitterIcon = <svg className={CardStyles.icon} aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#FFF" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></svg>;
const facebookIcon = <svg className={CardStyles.icon} aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#FFF" d="M448 56.7v398.5c0 13.7-11.1 24.7-24.7 24.7H309.1V306.5h58.2l8.7-67.6h-67v-43.2c0-19.6 5.4-32.9 33.5-32.9h35.8v-60.5c-6.2-.8-27.4-2.7-52.2-2.7-51.6 0-87 31.5-87 89.4v49.9h-58.4v67.6h58.4V480H24.7C11.1 480 0 468.9 0 455.3V56.7C0 43.1 11.1 32 24.7 32h398.5c13.7 0 24.8 11.1 24.8 24.7z"></path></svg>;
const linkedinIcon = <svg className={CardStyles.icon} aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#FFF" d="M100.3 448H7.4V148.9h92.9V448zM53.8 108.1C24.1 108.1 0 83.5 0 53.8S24.1 0 53.8 0s53.8 24.1 53.8 53.8-24.1 54.3-53.8 54.3zM448 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448h-.1z"></path></svg>;
 
const share = (socialNetwork, url) => {
  if(socialNetwork === 'twitter') {
    window.open(`http://www.twitter.com/share?url=${url}`,'_blank');
  } else if(socialNetwork === 'facebook') {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`,'_blank');
  } else if(socialNetwork === 'linkedin') {
    window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${url}`,'_blank');
  }
}

export const FactsCard =  React.forwardRef(({image,link, content}, ref) => (
  <div className={CardStyles.container} ref={ref}>
      <Img className={CardStyles.image} fixed={image} />
      <div className={CardStyles.contentWrapper}>
        <div className={CardStyles.content} dangerouslySetInnerHTML={{__html: content}}>
        </div>
        {!!link && !!link.url && !!link.title &&
         <Button to={link.url} target={link.target} style={{ backgroundColor: '#AAC939'}} dangerouslySetInnerHTML={{__html: link.title}} />
        }
      </div>
    { !!link.url && 
      <div className={CardStyles.buttons}>
        <button className={CardStyles.button} onClick={share.bind(this,'twitter', link.url)} >{twitterIcon}</button> 
        <button className={CardStyles.button} onClick={share.bind(this,'facebook', link.url)} >{facebookIcon}</button> 
        <button className={CardStyles.button} onClick={share.bind(this,'linkedin', link.url)} >{linkedinIcon}</button> 
      </div>
    }
    </div>

))
