import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout'
import Section from 'components/section';
import {FactSlider} from 'components/facts/slider'
import {MediaAndCard} from 'components/MediaAndCard'
import {ReactComponent as ChevronIcon} from 'images/icons/arrow.svg'
import {Link} from 'components/link'
import Style from './euroseeds.module.css'
import EuroseedsCard from 'components/euroseedsCard'
import BottomNavigation from 'components/BottomNavigation'

const DefaultTemplate = ({data, ...props}) => (
  <Layout
    articleId={`${data.wordpressPage.type}-${data.wordpressPage.wordpress_id}`}
    articleClassName={`${data.wordpressPage.classes}`}
    context = {props.pageContext}
    location = {props.location}
    ancestors={data.wordpressPage.ancestors}
  >
    <MediaAndCard
      title={data.wordpressPage.title}
      content={data.wordpressPage.acf.card_content}
      background={data.background.childImageSharp}
    />
    <div className="entry-content container mx-auto px-30px">
      <div className={`entry ${Style.content}`} style={{padding: 0}} dangerouslySetInnerHTML={{__html:data.wordpressPage.content}} />
    </div>
		<Section>
      <EuroseedsCard
        image={data.wordpressPage.acf.congress_card.image.localFile.childImageSharp}
        top={data.wordpressPage.acf.congress_card.top_text}
        title={data.wordpressPage.acf.congress_card.title}
        button={data.wordpressPage.acf.congress_card.button}
      />
    </Section>
    <Section
      title={data.wordpressPage.didYouKnowSection.title}
    >
      <FactSlider facts={data.wordpressPage.didYouKnowSection.facts} />
    </Section>
    {data.wordpressPage.showPageGroupWidget && data.wordpressPage.siblingPages && data.wordpressPage.siblingPages.length > 0 &&
     <Section>
       <BottomNavigation items={data.wordpressPage.siblingPages} />
     </Section>
    }

    <footer className="entry-footer"/>
  </Layout>
);


export default DefaultTemplate
export const pageDefautQuery = graphql `
query EuroseedsPageById($id: String!){
  background:file(relativePath: {eq: "euroseeds/cardBackground.jpg"}) {
    childImageSharp {
      fluid (maxWidth: 3000){
        ...GatsbyImageSharpFluid
      }
    }
  }
  wordpressPage(id:{eq:$id}){
    title
    content
    path
    type
    date
    wordpress_id
    showPageGroupWidget
     ancestors {
      link
      label
    }

    siblingPages {
      title
      excerpt
      path
    }

    classes
    acf {
      card_content
      congress_card{
        image{
          localFile {
            childImageSharp {
              fixed(height: 173, width: 173) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        top_text
        title
        button{
          url
          title
        }
      }
    }
    didYouKnowSection {
      title
      facts {
        featured_media {
          localFile {
            childImageSharp {
              fixed (height: 521, width: 574){
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        content
        acf {
          link {
            url
            title
            target
          }
        }
      }
    }
  }
}
`
