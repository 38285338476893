import React, { useState, useRef } from "react"
import Img from "gatsby-image/withIEPolyfill"
import BackgroundImage from "gatsby-background-image"
import ReactPlayer from "react-player"
import MediaQuery from "react-responsive"

import Styles from "./style.module.css"
import { ReactComponent as YoutubePlay } from "images/icons/youtubePlay.svg"

export const MediaAndCard = ({ title, content, background }) => {
	const image = require("./assets/cover2.png")
	const [playing, setPlaying] = useState(false)
	const imageRef = useRef()
	const [videoHeight, setVideoHeight] = useState(0)

	const playVideo = () => {
		const imageHeight = imageRef.current.clientHeight
		setVideoHeight(imageHeight + "px")
		setPlaying(true)
	}
	return (
		<div className={Styles.container}>
			<div className={Styles.image}>
				<MediaQuery query="(min-width: 1440px)">
					<BackgroundImage
						tag="div"
						fluid={background.fluid}
						className={Styles.content}
						style={{
							backgroundSize: "150px",
							backgroundPosition: "bottom left",
							position: "absolute",
						}}
					>
						<h2
							dangerouslySetInnerHTML={{ __html: title }}
							className={Styles.title}
						/>
						<p
							dangerouslySetInnerHTML={{ __html: content }}
							className={Styles.description}
						/>
					</BackgroundImage>
				</MediaQuery>
				{playing ? (
					<ReactPlayer
						url={"https://youtu.be/v1MOlNrJpdg"}
						playing={playing}
						width="100%"
						height={videoHeight}
						controls={false}
						loop={true}
						config={{
							youtube: {
								playerVars: { showinfo: 0 },
							},
						}}
					/>
				) : (
					<img ref={imageRef} src={image} onClick={playVideo} />
				)}
				{!playing && false && (
					<YoutubePlay className={Styles.icon} onClick={playVideo} />
				)}
			</div>
			<MediaQuery query="(max-width: 1439px)">
				<div className={Styles.mobileContent}>
					<h2
						dangerouslySetInnerHTML={{ __html: title }}
						className={Styles.title}
					/>
					<p
						dangerouslySetInnerHTML={{ __html: content }}
						className={Styles.description}
					/>
				</div>
			</MediaQuery>
		</div>
	)
}
