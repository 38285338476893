import React from 'react';

import {FactsCard} from './card'
import {withSlider} from 'components/slider'
import {MediaQuery} from 'react-responsive'

export const FactSlider = ({facts}) => {
  const factsFormatted = facts.map((fact) => {
    const image =fact.featured_media?.localFile?.childImageSharp?.fixed
    const {link} = fact.acf
    return {
      content:fact.content,
      image,
      link,
      id:fact.id
    }
  })
  const Slider = withSlider(FactsCard, factsFormatted, 80);

  return (
    <>
    <MediaQuery query="(min-width: 768px)">
      <Slider />
    </MediaQuery>
      <MediaQuery query="(max-width: 767px)">
        {factsFormatted.map(fact=> (
          <div key={fact.id}>
            <FactsCard {...fact}/>
          </div>

        ))}
    </MediaQuery>
    </>
  )
}
