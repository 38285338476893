import React, { Component } from 'react'
import Img from "gatsby-image/withIEPolyfill"

import Style from './style.module.css'

import {Button} from 'components/buttons'


const EuroseedsCard = (props) => {
  
  return(
    <div className={Style.container} style={{maxWidth: 560}}>
      <div className={Style.image}>
        <Img fixed={props.image.fixed} />
      </div>
      <div className={Style.content}>
        <div className={Style.date} dangerouslySetInnerHTML={{__html: props.top}} />
        <div className={Style.title} dangerouslySetInnerHTML={{__html: props.title}} />
        <Button target='_blank' to={props.button.url} style={{backgroundColor: '#AAC939', color: 'white'}}  dangerouslySetInnerHTML={{__html: props.button.title}} />
      </div>
    </div>
  )
}
export default EuroseedsCard
