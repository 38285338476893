import React, { Component } from 'react'

import {ReadMoreButton} from '../buttons'

import NavigationStyles from './bottom-navigation.module.css';

export class BottomNavigation extends Component {
  render() {
    const items = this.props.items
    return (
      <div className={NavigationStyles.container}>
        {items.map((item) => {
          return (
            <div className={NavigationStyles.item}>
              <h2 className={NavigationStyles.title} dangerouslySetInnerHTML={{__html:item.title}} />
              <ReadMoreButton link={{url: item.path}} color='black' symbolColor='#AAC939' className='bottom-navigation__button bottom-navigation__button-reset'>Read More</ReadMoreButton>
            </div>
          )
        })}
      </div>
    )
  }
}

export default BottomNavigation
